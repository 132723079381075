/* storybook-check-ignore */
import React, { useEffect, useState } from 'react';

import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Link,
  LinkProps,
  Text,
  TextProps,
} from '@opendoor/bricks/core';
import { ChakraGenericColors } from '@opendoor/bricks/system/chakra.styles.types';

import { MARKET_TEXT } from '../../../declarations/exclusives/market';
import { useExclusivesTypeContext } from '../../../helpers/exclusives/ExclusivesTypeContext';
import { NC_WWREAD_FORM_LINK } from '../constants/constants';
import { OpendoorRoutes } from '../constants/routes';
import HomeIcon from '../icons/HomeIcon';

type FooterProps = Omit<BoxProps, ChakraGenericColors> & {
  flexContainerProps?: FlexProps;
  colorMode?: 'dark' | 'light';
  type?: 'exclusive-offers' | 'exclusives';
  onFAQsClick?: () => void;
  footerText?: React.ReactNode[];
};

const Footer: React.FC<FooterProps> = ({
  flexContainerProps,
  colorMode = 'light',
  footerText = footerTextDefault,
  ...props
}) => {
  const { type } = useExclusivesTypeContext();
  const analyticsPrefix = `cosmos-${type}-footer`;

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentUrl(window.location.href);
    }
  }, []);

  // These are the gallery pages for Raleigh and Charlotte
  const isRaleigh = currentUrl.includes('/raleigh');
  const isCharlotte = currentUrl.includes('/charlotte');
  // if a slug has -NC or -nc in it, it's a North Carolina home
  const isNC = currentUrl.includes('-NC') || currentUrl.includes('-nc');

  const displayWwreForm = isRaleigh || isCharlotte || isNC;

  const FooterText: React.FC<TextProps> = ({ color, ...textProps }) => (
    <Text
      size="s0"
      mb={5}
      color={colorMode === 'dark' ? 'neutrals70' : color ?? 'neutrals100'}
      {...textProps}
    />
  );

  const FooterLink: React.FC<LinkProps> = ({ color, ...linkProps }) => (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      variant="inline-primary"
      color={colorMode === 'dark' ? 'neutrals0' : color ?? 'neutrals70'}
      {...linkProps}
    />
  );

  return (
    <Box bgColor={colorMode === 'dark' ? 'neutrals100' : 'neutrals0'} {...props}>
      <Flex
        analyticsName={`cosmos-${type}-section-footer`}
        as="footer"
        flexDirection="column"
        pt="4rem"
        maxWidth="var(--header-max-width)"
        mx="auto"
        py="2rem"
        px={['1.5rem', '2rem']}
        {...flexContainerProps}
      >
        {footerText.map((content, index) => (
          <FooterText key={index}>{content}</FooterText>
        ))}
        <FooterText lineHeight="s00" pt="0.75rem">
          {Object.entries(MARKET_TEXT).map(([market, text], index) => (
            <React.Fragment key={index}>
              {index > 0 && ' · '}
              <FooterLink
                href={`/exclusives/gallery/${market}`}
                aria-label={`View all ${text} homes`}
                analyticsName={`${analyticsPrefix}-gallery-${market}`}
              >
                {text}
              </FooterLink>
            </React.Fragment>
          ))}
        </FooterText>
        <FooterText lineHeight="s00" pt="0.75rem" pb="2rem">
          © {new Date().getFullYear()} Opendoor{' '}
          <FooterLink
            href="https://opendoor.com/terms"
            aria-label="Go to Opendoor terms of service page."
            analyticsName={`${analyticsPrefix}-terms-of-service-page`}
          >
            Terms of Service
          </FooterLink>{' '}
          ·{' '}
          <FooterLink
            href="https://opendoor.com/privacy"
            aria-label="Go to Opendoor privacy policy page."
            analyticsName={`${analyticsPrefix}-privary-policy`}
          >
            Privacy Policy
          </FooterLink>{' '}
          ·{' '}
          <FooterLink
            href={OpendoorRoutes.CCPA}
            aria-label="Go to Opendoor notice at collection page."
            analyticsName={`${analyticsPrefix}-notice-at-collection`}
          >
            Notice at Collection
          </FooterLink>{' '}
          ·{' '}
          <FooterLink
            href="https://opendoor-privacy.relyance.ai/"
            aria-label="Go to Opendoor privacy request page."
            analyticsName={`${analyticsPrefix}-privacy-request`}
          >
            Do Not Sell or Share my Personal Information
          </FooterLink>{' '}
          ·{' '}
          <FooterLink
            href={type === 'exclusives' ? '/exclusives/faq' : undefined}
            onClick={type === 'exclusive-offers' ? props?.onFAQsClick : undefined}
            aria-label="Learn more about exclusive listings at Opendoor."
            analyticsName={`${analyticsPrefix}-privacy-FAQs`}
          >
            FAQ
          </FooterLink>{' '}
          ·{' '}
          <FooterLink
            href="https://drive.google.com/file/d/1x0sT_FhzsML30lDv6HpFGr9f_izW4jcJ/view?usp=sharing"
            aria-label="Info about brokerage services document."
            analyticsName={`${analyticsPrefix}-info-about-brokerage-services`}
            download
          >
            Info About Brokerage Services
          </FooterLink>{' '}
          ·{' '}
          <FooterLink
            href="https://www.trec.texas.gov/forms/consumer-protection-notice"
            aria-label="Go to consumer protection notice page."
            analyticsName={`${analyticsPrefix}-consumer-protection-services`}
          >
            Consumer Protection Notice
          </FooterLink>{' '}
          {displayWwreForm && (
            <>
              ·{' '}
              <FooterLink
                href={NC_WWREAD_FORM_LINK}
                aria-label="Go to consumer protection notice page."
                analyticsName={`${analyticsPrefix}-nc-working-with-re-disclosure`}
              >
                Working With Real Estate Agents Disclosure
              </FooterLink>
            </>
          )}
        </FooterText>
        <HomeIcon />
        <br />
      </Flex>
    </Box>
  );
};

export default Footer;

const footerTextDefault = [
  'Opendoor-Owned Exclusives are owned and advertised by Opendoor and its affiliated companies. For these homes, no listing, advertising, or marketing services are provided by, and no commissions are received by, the Opendoor Brokerages as part of the Exclusives program.',
  'Customer-Owned Exclusives, where available, are owned by third party sellers and are advertised by Opendoor Connect, a non-MLS member, licensed brokerage, and its affiliated companies. Opendoor Connect serves sellers only, and does not represent buyers or provide buyer agency services.',
  'In all cases, property information is not provided by a MLS. It is deemed reliable but not guaranteed, is provided "AS IS" without any warranty (express or implied), and should be independently verified.',
];
